<template>
    <!-- 我的客户 -->
    <div class="customer_box">
        <div>
            <div class="alert"><i style="color:#078fea;margin-right:10px;" class="el-icon-warning"></i><span>销售数量从2024年6月1日开始计算，按照仓库实际发货数量进行计算，赠品被统计在内。</span></div>

        </div>
        <div class="table_mode">
             <el-table ref="expandTable" v-if="tableData.length" :data="tableData" @cell-click="cellClick" :cell-style="cellStyle"  show-summary  :summary-method="getSummaries" v-loading="loading" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" stripe style="width: 100%">
                <el-table-column prop="title" label="大区-省区"  min-width="200" :show-overflow-tooltip="true" fixed align="center">
                    <template slot-scope="scope">
                        <span style="color:#078fea;cursor:pointer;" @click="goExpanded(scope.row)">{{scope.row.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="reward" label="认购奖励（元）" min-width="1120" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="target" label="认购目标" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="sale" label="销售数量" min-width="130" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="diff" label="销售差额" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="schedule" label="销售进度" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
            <el-table  :data="tableData1" v-else v-loading="loading"  show-summary  :summary-method="getSummaries1" stripe style="width: 100%">
                <el-table-column prop="productName" label="商品名称" min-width="200" :show-overflow-tooltip="true" align="center" fixed></el-table-column>
                <el-table-column prop="norms" label="规格" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="produceUnit" label="厂家" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="geer" label="认购档位" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="reward" label="认购奖励（元）" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="target" label="认购目标" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <!-- <el-table-column prop="sale" label="销售数量" min-width="130" :show-overflow-tooltip="true" align="center">

                </el-table-column>
                <el-table-column prop="diff" label="销售差额" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="schedule" label="销售进度" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column> -->
                <el-table-column prop="sale" label="销售数量" min-width="90" :show-overflow-tooltip="true" align="center">
                    <template slot="header" slot-scope="scope">
                        <div>销售数量
                            <el-tooltip class="item" effect="dark" :content="authTitle=='客户经理'?'该数据仅统计当前登录人的销售数量':'该数据统计当前省办所有客户经理的销售数量'" placement="top">
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="diff" label="销售差额" min-width="90" :show-overflow-tooltip="true" align="center">
                    <template slot="header" slot-scope="scope">
                        <div v-if="authTitle=='客户经理'">销售差额
                            <el-tooltip class="item" effect="dark" :content="'此差额为省办的总销售差额'" placement="top">
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </div>
                        <div v-else>
                          销售差额
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="schedule" label="销售进度" min-width="90" :show-overflow-tooltip="true" align="center">
                    <template slot="header" slot-scope="scope">
                        <div  v-if="authTitle=='客户经理'">销售进度
                            <el-tooltip class="item" effect="dark" :content="'此进度为省办总进度'" placement="top">
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </div>
                         <div v-else>
                          销售进度
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
           
        </div>
        <!-- <div class="pagination_box">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getCustomerList" @size-change="getCustomerList(1)">
            </el-pagination>
        </div> -->
       <el-dialog :title="saleTitle" :visible.sync="tableVisible" >
  <el-table :data="tableData1" ref="saleTable" show-summary  :summary-method="getSummaries1" style="max-height:1200px;  overflow-y: auto;">
        <el-table-column prop="productName" label="商品名称" min-width="200" :show-overflow-tooltip="true" align="center" fixed></el-table-column>
                <el-table-column prop="norms" label="规格" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="produceUnit" label="厂家" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="geer" label="认购档位" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="reward" label="认购奖励（元）" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="target" label="认购目标" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="sale" label="销售数量" min-width="80" :show-overflow-tooltip="true" align="center">

                </el-table-column>
                <el-table-column prop="diff" label="销售差额" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="schedule" label="销售进度" min-width="80" :show-overflow-tooltip="true" align="center" ></el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
  </el-table>
</el-dialog>
    </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
    name: 'MyCustomer',
    data() {
        return {
            loading: false,
            total: 0,
            provinceOptions: [],
            cityOptions: [],
            areaOptions: [],
            // filterinfo: {
            //     orderBy: '',
            //     page: 1,
            //     pageSize: 10,
            //     search: '',
            //     shopType: undefined,
            //     attr: undefined,
            // },
            tableData: [{
          id: 1,
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          id: 2,
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          id: 3,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄',
          children: [{
              id: 31,
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄'
            }, {
              id: 32,
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄'
          }]
        }, {
          id: 4,
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }],
            tableData1: [],
            // tableData: [],
            attributeOptions: [],
            options: [],
            roleNumber: localStorage.getItem('roleNumber'),
            tableVisible: false,
            flag: false,
            summary: {},
            summary1: {},
            saleTitle: '',
            authTitle: '',
        };
    },
    created() {
     
    },
    mounted() {
        this.getSaleList()
//   this.getSaleDetail(0)
        console.log(this.roleNumber);
   
    },
    methods: {
        cellClick(row, column) {
            if (column.label.includes('省区') && !row.title.includes('区')) {
                console.log(row, column);
                this.getSaleDetail(row.id)
                
            }


        },
         cellStyle ({row, column, rowIndex, columnIndex}) {
      if (column.label.includes('省区') ) {
        // 给所点击的 单元格添加样式
          return "cursor:pointer;color: #389DFF"
      
      }
    },
    goExpanded(i){
        this.flag=!this.flag;
         this.$refs.expandTable.toggleRowExpansion(i, this.flag);
        
    },
          getSummaries(param) {
        const sums = [];
       sums[0] = this.summary.title;
       sums[1] = this.summary.reward;
       sums[2] = this.summary.target;
       sums[3] = this.summary.sale;
       sums[4] = this.summary.diff;
       sums[5] = this.summary.schedule;

        return sums;
      },
          getSummaries1(param) {
        const sums = [];
       sums[0] = this.summary1.productName;
       sums[4] = this.summary1.reward;
       sums[5] = this.summary1.target;
       sums[6] = this.summary1.sale;
       sums[7] = this.summary1.diff;
       sums[8] = this.summary1.schedule;
// this.$nextTick(() => {
//       // myTable是表格的ref属性值
//       if (this.$refs.saleTable && this.$refs.saleTable.doLayout) {
//         this.$refs.saleTable.doLayout();
//       }
//     });
        return sums;
      },
              // 获取我的客户列表
        getSaleList() {

            this.loading = true
            this.$api.saleNumList().then((res) => {
                this.loading = false
               if(res.data.code==200){
                 if (res.data.msg === 'OK') {
                    this.tableData = handlerTableData(res.data.data.keynoteSchedule)
                   console.log(this.tableData);
                   this.summary=res.data.data.summary
                }
                else if(res.data.msg=='goToNext'){
                    this.tableData=[]
                    this.getSaleDetail(0)
                }
               }
            });
        },
        getSaleDetail(id) {
            
         
                this.loading = true
          
            this.$api.saleNumDetail({id}).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData1 = handlerTableData(res.data.data.keynoteScheduleDetail)

                   console.log(this.tableData1);
                   this.summary1=res.data.data.summary
                   this.saleTitle=res.data.data.title+'重点产品销售进度'
                   this.authTitle=res.data.data.authTitle

                }
                if(id){
                    this.tableVisible=true
                }
            });
        },
       
    }
};
</script>
<style lang="scss" scoped>
.customer_box {
    position: relative;
}
.el-form-item__label {
    text-align: left !important;
}
.alert {
    display: flex;
    align-items: center;
    width: 690px;
    // margin-left: 20px;
    // justify-content: center;
    border: 1px solid #fff3c6;
    font-size: 16px;
    background: #e6f7ff;
    padding: 5px;
    margin-bottom: 20px;
    color: #999;
    border: 1px #bae7ff solid;
}

</style>
<style lang="scss" >
.self-dialog {
    width: 500px;
    border-radius: 10px !important;
    .el-form-item__content {
        display: flex !important;
    }
}
.el-dialog{
        margin-top: 10vh !important;
}
</style>